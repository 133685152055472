@import '../../../styles/customMediaQueries.css';

/* Styles for custom sections: classes shared by <SectionColumns>, <SectionArticle>, <SectionFeatures>, <SectionCarousel> etc. */
.sectionDetails {
  max-width: var(--contentMaxWidth);
  display: grid;
  justify-content: start;
  margin: 0 auto;
  padding: 0 32px;
  position: relative;

  & h1 + p,
  & h1 + a,
  & h1 + ul,
  & h1 + ol,
  & h1 + code,
  & h1 + div,
  & h1 + h1,
  & h1 + h2,
  & h1 + h3,
  & h1 + h4,
  & h1 + h5,
  & h1 + h6 {
    margin-top: 12px;
  }

  & h2 + p,
  & h2 + a,
  & h2 + ul,
  & h2 + ol,
  & h2 + code,
  & h2 + div,
  & h2 + h1,
  & h2 + h2,
  & h2 + h3,
  & h2 + h4,
  & h2 + h5,
  & h2 + h6 {
    margin-top: 12px;
  }

  & h3 + p,
  & h3 + a,
  & h3 + ul,
  & h3 + ol,
  & h3 + code,
  & h3 + div,
  & h3 + h1,
  & h3 + h2,
  & h3 + h3,
  & h3 + h4,
  & h3 + h5,
  & h3 + h6 {
    margin-top: 12px;
  }

  & h4 + p,
  & h4 + a,
  & h4 + ul,
  & h4 + ol,
  & h4 + code,
  & h4 + div,
  & h4 + h1,
  & h4 + h2,
  & h4 + h3,
  & h4 + h4,
  & h4 + h5,
  & h4 + h6 {
    margin-top: 12px;
  }

  & h5 + p,
  & h5 + a,
  & h5 + ul,
  & h5 + ol,
  & h5 + code,
  & h5 + div,
  & h5 + h1,
  & h5 + h2,
  & h5 + h3,
  & h5 + h4,
  & h5 + h5,
  & h5 + h6 {
    margin-top: 12px;
  }

  & h6 + p,
  & h6 + a,
  & h6 + ul,
  & h6 + ol,
  & h6 + code,
  & h6 + div,
  & h6 + h1,
  & h6 + h2,
  & h6 + h3,
  & h6 + h4,
  & h6 + h5,
  & h6 + h6 {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    justify-content: center;
  }
}

.blockContainer {
  padding: 64px 32px 0 32px;
  @media (--viewportLarge) {
    padding: 64px 94px 0px 60px;
  }
  @media (max-width: 1600px) {
    padding: 64px 32px 0 32px;
  }
  &:first-child {
    padding: 0 32px;
    @media (--viewportLarge) {
      padding: 0 94px 0px 60px;
    }
    @media (max-width: 1679px) {
      padding: 0 22px 0px 22px;
    }
  }
}

.align {
  text-align: left;
  justify-self: start;

  @media (--viewportMedium) {
    text-align: center;
    justify-self: center;
  }
}

.title {
  composes: align;
  max-width: 30ch;
}

.description {
  composes: align;
  max-width: 65ch;
}
.listingCard {
  width: 100%;
}

.ctaButton {
  composes: align;
  display: inline-block;
  padding: 8px 20px;
  font-size: 15px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;
  color: white;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 500;
  margin-top: 24px;

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
  }
}

/**
 * Theme: dark 
 * These styles are at the bottom of the file so that they overwrite rules for default "light" theme.
 */
.darkTheme h1,
.darkTheme h2,
.darkTheme h3,
.darkTheme h4,
.darkTheme h5,
.darkTheme h6 {
  color: var(--matterColorLight);

  &::selection {
    background-color: cyan;
    color: unset;
  }
}

.darkTheme p,
.darkTheme li,
.darkTheme blockquote {
  color: rgba(255, 255, 255, 0.85);

  &::selection {
    background-color: cyan;
    color: unset;
  }
}

/* link on dark theme */
.darkTheme a {
  color: white;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: var(--marketplaceColorLight);
  }
}

/* button on dark theme */
.darkTheme .ctaButton {
  border: 1px solid var(--marketplaceColorDark);
  text-decoration: none;

  &:hover {
    color: white;
  }
}

.darkTheme hr {
  border-color: var(--matterColor);
}

/* dark inline code */
.darkTheme code {
  background-color: var(--matterColor);
  color: var(--matterColorNegative);
}

/* dark code block */
.darkTheme pre {
  background-color: var(--matterColor);
}

.ListingCards {
  /* display: flex;
  flex-wrap: wrap; */
}
/* .listingCard{
  width: 33%;
} */

.ListingCardsSkeleton {
  padding: 0 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 9px;

  width: 100%;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 1fr);
    padding: 0 0px;
  }
}

.articleCardsSkeleton {
  composes: ListingCardsSkeleton;
  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 0px;
  }
}

.categoryCardsSkeleton {
  composes: ListingCardsSkeleton;
  @media (--viewportLarge) {
    grid-template-columns: repeat(6, 1fr);
    padding: 0 0px;
  }
}

.ListingCards {
  border-top: 1px solid #979797;
  padding-top: 56px;

  background: #fff;
  &:last-child {
    margin-top: 30px;
  }
  @media (max-width: 1023px) {
    border: 0;
    max-width: 100vw;
    padding-top: 0px;
    margin-top: 28px;
  }
  & :global(.slick-list) {
    max-width: 1680px;
    margin: 0 auto;
    height: 500px;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #edf5fa;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #121313;
    }
  }
  & :global(.slick-slide) {
    padding: 0 20px;
  }
  & :global(.slick-slider) {
    width: 100%;
  }
  & :global(.slick-arrow) {
    top: 40% !important;
  }
}

.HeroSLiderWrapper {
  background: #fff;
  padding-bottom: 51px;
  @media (max-width: 1680px) {
    max-width: calc(100vw - 0px);
    overflow: hidden;
  }
  @media (max-width: 1023px) {
    max-width: calc(100vw);
    padding-bottom: 0px;
  }
}

.titleWithShadow {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 97px 0 60px;

  @media (max-width: 1680px) {
    padding: 0 22px 0 22px;
  }
  @media (max-width: 1023px) {
    padding: 0px 17px 0 17px;
  }
  & h1 {
    font-size: 23px;
    font-style: normal;
    color: var(--matterColor);
    margin: 0;
    @media (max-width: 1023px) {
      font-size: 18px;
    }
  }
  & .viewAllBtn {
    border-radius: 4px;
    cursor: pointer;
    background: var(--marketplaceInitialDefaultColor);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98px;
    height: 39px;
    flex-shrink: 0;
    font-size: 18px;
    @media (max-width: 1023px) {
      font-size: 16px;
      width: 89px;
      height: 35px;
    }
  }
}

.HeroSlider {
  display: flex;
  max-width: 1680px;
  padding: 0px 60px;
  margin: 0 auto;
  gap: 28px;
  @media (max-width: 1680px) {
    padding: 0px 30px;
  }
  @media (max-width: 1023px) {
    padding: 0px 17px;
  }

  & .sliderLeft {
    max-width: 914px;
    width: 100%;
    padding: 0 4px;
    border: 3px solid var(--marketplaceColor);
    border-radius: 4px;
    max-height: 400px;
    overflow: hidden;
    @media (max-width: 1680px) {
      aspect-ratio: 18/9;
      max-width: 60%;
      max-height: 100%;
    }
    @media (max-width: 1023px) {
      max-width: 100vw;
    }

    & :global(.slick-list) {
      max-width: 100%;
      @media (max-width: 1023px) {
        max-width: calc(100vw - 40px);
      }
    }
    & img {
      width: 100%;
      height: 100%;
      @media (max-width: 1680px) {
        aspect-ratio: 18/9;
      }
      @media (max-width: 1023px) {
        aspect-ratio: 18/9;
      }
    }
    & .sliderImageWrapper {
      position: relative;
      & .sliderContent {
        position: absolute;
        z-index: 3;
        top: 60px;
        left: 62px;
        @media (max-width: 1440px) {
          top: 20px;
        }
        @media (max-width: 1023px) {
          display: none;
        }
        & h4 {
          color: #fff;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-size: 44px;
          font-style: normal;
          font-weight: 900;
          line-height: 55px; /* 125% */
          text-transform: capitalize;
          max-width: 560px;
          @media (max-width: 1440px) {
            font-size: 34px;
            line-height: 40px;
          }
        }
        & button {
          width: auto;
          padding-left: 19px;
          padding-right: 19px;
          margin-top: 50px;
          @media (max-width: 1440px) {
            margin-top: 20px;
          }
          & svg {
            margin-right: 13px;
          }
        }
      }
    }
  }
  & :global(.slick-dots) {
    position: absolute;
    bottom: 20px;
    @media (min-width: 1024px) {
      text-align: left;
      left: 62px;
      bottom: 60px;
    }
    & :global(.slick-active) {
      & button:before {
        color: var(--marketplaceColor) !important;
        opacity: 1;
      }
    }
    & li {
      @media (max-width: 1023px) {
        width: 10px;
        height: 10px;
      }

      & button:before {
        font-size: 10px;
        color: #fff;
        opacity: 1;
        @media (min-width: 1024px) {
          font-size: 17px;
        }
      }
    }
  }

  & .sliderRight {
    max-width: 576px;
    width: 100%;
    padding: 0 4px 4px;
    border: 3px solid var(--marketplaceColor);
    border-radius: 4px;
    max-height: 401px;

    @media (max-width: 1680px) {
      aspect-ratio: 18/9;
      max-width: 38%;
      max-height: 100%;
    }
    @media (max-width: 1023px) {
      max-width: 100vw;
      display: none;
    }
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media (max-width: 1680px) {
        aspect-ratio: 18/9;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.sliderLeft > div {
  margin-top: 6px;
}

.sliderRight > div {
  margin-top: 3px;
  height: 100%;
  & :global(.slick-list) {
    height: 100%;
  }
  & :global(.slick-track) {
    height: 100%;
  }
  & :global(.slick-slide) {
    height: 100%;
    & div {
      height: 100%;
    }
  }
}

.SectionArticles {
  background: #fff;
  width: 100%;
  & .sectionHeader {
    padding: 0 90px 0px 67px;
    @media (max-width: 1680px) {
      padding: 0 30px 0px 30px;
    }
    @media (max-width: 767px) {
      padding: 0 17px 0px 17px;
    }
    & h2 {
      color: #4a4a4a;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 56px; /* 233.333% */
      text-transform: uppercase;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
  }
  & .smallText {
    color: var(--matterColor);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px; /* 206.25% */
    margin-top: 17px;
  }
  & .titleText {
    color: var(--matterColor);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px; /* 150% */
  }
  & .articlesItem {
    margin: 0 auto;
    padding: 0 90px 50px 67px;
    max-width: 1680px;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    & img {
      width: 100%;
    }
    @media (max-width: 1680px) {
      padding: 0 30px 50px 30px;
    }
    @media (max-width: 767px) {
      padding: 0 17px 50px 17px;
      flex-wrap: wrap;
    }
    & > div {
      flex: 1 1;
      max-width: 422px;
      @media (max-width: 767px) {
        max-width: 100%;
        width: 100%;
        flex: 100%;
      }
    }
  }
}

.mainBlog {
  & h1 {
    color: var(--matterColor);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px; /* 150% */
  }
  & h2 {
    color: var(--matterColor);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px; /* 150% */
  }
  & h3 {
    color: var(--matterColor);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px; /* 150% */
  }
  & p {
    display: none;
  }
  & a div * {
    display: none;
  }
  & h1 {
    display: block !important;
  }
  & img {
    max-height: 240px;
    object-fit: cover;
    display: block !important;
    object-position: top;
  }
  flex: 1 1;
  & > a {
    &:hover {
      text-decoration: none;
    }
  }
}

.ProductSliderSkeleton {
  display: flex;
  max-width: 1680px;
  padding: 0px 60px;
  margin: 0 auto;
  gap: 28px;
}

.ProductCardSkeletonWrapper {
  max-width: 1680px;
  padding: 0px 60px;
  margin: 0 auto;
  @media (max-width: 1023px) {
    padding: 0px;
  }
}

.skeletonTitle {
  @media (max-width: 1023px) {
    padding: 0px 20px;
  }
}

.categoryItems {
  /* display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 71px; */
  max-width: 1680px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 0 90px 0px 67px;
  @media (max-width: 1680px) {
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    gap: 30px;
    padding: 64px 30px 0px 30px;
  }
  @media (max-width: 1126px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 767px) {
    /* gap: 34px 15px;
    padding: 30px 17px 0; */

    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    padding: 9px 17px;
    & .text {
      margin-top: 3px;
    }
  }
  & .cartegoryItem {
    padding: 0 6px;
  }
  & :global(.slick-track) {
    display: flex;
  }

  & :global(.slick-list) {
    max-width: 1680px;
    width: 100%;

    @media (max-width: 1680px) {
      max-width: calc(100vw - 60px);
    }
    @media (max-width: 1023px) {
      max-width: calc(100vw - 44px);
    }
  }
  & :global(.slick-prev) {
    @media (max-width: 1023px) {
      left: -15px !important;
    }
    
  }
  & :global(.slick-next) {
    @media (max-width: 1023px) {
  
      right: -15px !important;
    }
  }
  & :global(.slick-arrow) {
    top: 35%;
    @media (max-width: 1023px) {
      top: 25%;
    }
    & path {
      fill: var(--marketplaceInitialDefaultColor);
    }
    @media (max-width: 1680px) {
      & svg {
        height: 26px;
        width: 26px;
      }
    }
  }

  & .media {
    background: var(--matterColorBright);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 95px;
    width: 100%;
    margin: 0 auto;
    @media (min-width: 767px) {
      height: 195px;
    }
    @media (min-width: 1680px) {
      height: 195px;
      width: 191.429px;
    }
    & a {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: 1023px) {
      & svg {
        max-height: 55px;
      }
    }

    &:hover {
      background: var(--marketplaceInitialDefaultColor);
      & svg {
        & path {
          fill: #fff !important;
        }
      }
    }
    & > div {
      & > div {
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    & img {
      object-fit: contain !important;
      max-width: 92px;
    }
  }
  & .text {
    text-align: center;
    display: block;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 177.778% */
    letter-spacing: -0.75px;
    & h3 {
      @media (max-width: 767px) {
        font-size: 14px;
        font-style: normal;
      }
    }
  }
  & > div {
    /* width: calc(16% - 49px); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 1630px) {
      /* width: calc(20% - 60px); */
    }
    @media (max-width: 1126px) {
      /* width: calc(25% - 60px); */
    }
    @media (max-width: 767px) {
      /* width: calc(33% - 20px); */
      margin-bottom: 30px;
    }
  }
}
