@import '../../styles/customMediaQueries.css';
.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
  @media (max-width: 1023px) {
    display: block !important;
  }
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
}
.menuContentVacation {
  position: absolute;
  right: unset;
  left: 0 !important;
  z-index: var(--zIndexPopup);
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
  border-radius: 6px;
  max-width: 250px;
  width: 100%;
  min-width: 250px !important;
  bottom: 28px;
  @media (max-width: 767px) {
    bottom: 24px;
    right: 0;
    left: unset !important;
    max-width: 210px;
    min-width: 210px !important;
    padding: 10px 6px 6px;
    left: -20px !important;
  }
  & > div {
    display: none;
  }
  & ul {
    & li {
      padding: 0 0 8px;
      color: #cbcbcb;
      &:nth-child(1) {
        color: #9e9e9e;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 12px */
        letter-spacing: -0.1px;
        @media (max-width: 767px) {
          font-size: 8px;
        }
      }
      &:last-child {
        border-bottom: none;
        padding: 0;
        margin: 0;
      }
    }
  }
}
.seectionWrapper {
  background: #fff;
}

.ProductSliderSkeleton {
  display: flex;
  max-width: 1680px;
  padding: 0px 60px;
  margin: 0 auto;
  gap: 28px;
}

.ProductCardSkeletonWrapper {
  max-width: 1680px;
  padding: 0px 60px;
  margin: 0 auto;
  @media (max-width: 1023px) {
    padding: 0px;
  }
}

.skeletonTitle {
  @media (max-width: 1023px) {
    padding: 0px 20px;
  }
}
.ListingCardsSkeleton {
  padding: 0 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 9px;

  width: 100%;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 1fr);
    padding: 0 0px;
  }
}

.articleCardsSkeleton {
  composes: ListingCardsSkeleton;
  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 0px;
  }
}

.categoryCardsSkeleton {
  composes: ListingCardsSkeleton;
  @media (--viewportLarge) {
    grid-template-columns: repeat(6, 1fr);
    padding: 0 0px;
  }
}

.ListingCards {
  border-top: 1px solid #979797;
  padding-top: 56px;

  background: #fff;
  @media (max-width: 1023px) {
    border: 0;
    max-width: 100vw;
    padding-top: 0px;
    margin-top: 28px;
  }
  & :global(.slick-list) {
    max-width: 1680px;
    margin: 0 auto;
    height: 500px;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #edf5fa;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #121313;
    }
  }
  & :global(.slick-slide) {
    padding: 0 20px;
  }
  & :global(.slick-slider) {
    width: 100%;
  }
  & :global(.slick-arrow) {
    top: 40% !important;
  }
}
.listingCard {
  width: 100%;
}

.CategoryresultsFound {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
  max-width: 1680px;
  width: 100%;
  margin: 0 auto;
  padding: 28px 95px 28px 60px;
  background: #fff;
  @media (max-width: 1680px) {
    padding: 28px 30px 28px 30px;
    gap: 35px;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
    padding: 28px 0px 28px 0px;
    gap: 24px;
  }
  @media (max-width: 767px) {
  }
  & .category {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    width: 100%;
    @media (max-width: 1023px) {
      padding: 0 22px;
      & > div:not(:last-child) {
        position: relative;
        &:after {
          position: absolute;
          content: '';
          width: 1px;
          height: 37.955px;
          background: #3065a1;
          right: -15px;
          top: 15px;
        }
      }
    }
    & > div:nth-child(1) {
      & .activeStore {
        & .icon {
          background: var(--marketplaceColorDark);
          border-radius: 50%;
        }
      }
    }
    & > div:nth-child(2) {
      & .activeStore {
        & .icon {
          background: #d7282f;
          border-radius: 50%;
        }
      }
    }
    & > div:nth-child(3) {
      & .activeStore {
        & .icon {
          background: #e87200;
          border-radius: 50%;
        }
      }
    }
    & > div:nth-child(4) {
      & .activeStore {
        & .icon {
          background: #61a60e;
          border-radius: 50%;
        }
      }
    }
    & * {
      color: var(--matterColor);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 32px;
      @media (max-width: 1023px) {
        font-size: 14px;
      }
    }
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  @media (max-width: 1680px) {
    gap: 6px;
    margin-left: 10px;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  & .icon {
    height: 53px;
    width: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.activeStore {
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--marketplaceColor);
  background: #f8f7f7;
  max-width: 576px;
  width: 100%;
  padding: 20px 40px 0;
  @media (max-width: 1023px) {
    padding: 15px 40px 0;
  }
  @media (max-width: 1023px) {
    border: 0px solid var(--marketplaceColor);
    border-top: 1px solid var(--marketplaceColor);
    border-bottom: 1px solid var(--marketplaceColor);
    border-radius: 0;
    background: #fff;
    max-width: 100vw;
  }
  & .tabItems {
    color: var(--marketplaceColor);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
    letter-spacing: -0.305px;
    padding-bottom: 20px;
    border-bottom: 4px solid transparent;
    padding: 0 15px 20px;
    @media (max-width: 1023px) {
      font-size: 16px;
      padding: 0 15px 15px;
    }
    &:hover {
      font-weight: 900;
      cursor: pointer;
      border-bottom: 4px solid var(--marketplaceColor);
    }
  }
  & .test {
    color: var(--marketplaceColor);
    font-size: 18px;
    font-style: normal;
    line-height: 20px; /* 111.111% */
    letter-spacing: -0.305px;
    padding-bottom: 20px;
    padding: 0 15px 20px;
    font-weight: 900;
    cursor: pointer;
    border-bottom: 4px solid var(--marketplaceColor);
    @media (max-width: 1023px) {
      font-size: 16px;
      padding: 0 15px 15px;
    }
    /* &:hover {
      font-weight: 900;
      cursor: pointer;
      border-bottom: 4px solid var(--marketplaceColor);
    } */
  }
}

.modalTitle{
  margin: 0;
  margin-top: -30px;
  font-size: 28px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.modalDetails{
  display: flex;
  margin-top: 30px;
}
.Modallink{
  text-decoration: underline;
  & svg{
    margin-left: 8px;
  }
}

.modalShipping{
  & > div{
    & > div{
      & >button{
        display: none !important;
      }
    }
  }
}