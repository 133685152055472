.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

:global(#hero) {
  & .root {
    position: relative;
    & a {
      background: rgba(34, 35, 45, 0.66);
    }
    &:first-child {
      & a {
        &:hover {
          background: rgba(35, 141, 193, 0.5);
        }
      }
    }
    &:nth-child(2) {
      & a {
        &:hover {
          background: rgba(215, 40, 47, 0.5);
        }
      }
    }
    &:nth-child(3) {
      & a {
        &:hover {
          background: rgba(232, 114, 0, 0.5);
        }
      }
    }
    &:nth-child(4) {
      & a {
        &:hover {
          background: rgba(97, 166, 14, 0.5);
        }
      }
    }
  }
  & .media {
  }
  & .text {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(34, 35, 45, 0.37);
    margin: 0;
  }
  & .titleWrapper {
    display: flex;
    align-items: center;
  }
  & .buyText {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 33px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -2.5px;
    @media (min-width: 768px) and (max-width: 1300px) {
      font-size: 22px;
    }
  }
  & .textWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    & .iconWrapper {
      height: 115px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & svg {
      fill: transparent;
    }
    & a {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &:hover {
        text-decoration: none;
      }
    }
    & h1,
    & h3 {
      color: #fff;
      text-align: center;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 64px; /* 256% */
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      @media (min-width: 768px) and (max-width: 1300px) {
        font-size: 22px;
      }
    }
  }
}

:global(#shop-by-category) {
  & > div {
    @media (max-width: 1023px) {
      padding-top: 0;
    }
    & header {
      border-top: 1px solid #8e8e8e;
      text-align: left;
      display: block;
      max-width: 1560px;
      width: 100%;
      margin: 0 auto;
      padding: 32px 0 0;
      @media (max-width: 1630px) {
        padding: 32px 22px 0 22px;
      }
      @media (max-width: 1023px) {
        border-top: 0px solid #8e8e8e;
        padding: 0px 22px 0 22px;
      }
      & h2 {
        text-align: left;
        color: var(--matterColor);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 56px; /* 233.333% */
        text-transform: uppercase;
        max-width: 100%;
        @media (max-width: 1023px) {
          font-size: 18px;
        }
      }
    }
    & > div {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      max-width: 1680px;
      gap: 71px;
      height: 100%;
      @media (max-width: 1680px) {
        /* grid-template-columns: 1fr 1fr 1fr 1fr; */
        gap: 30px;
        padding: 64px 30px 0px 30px;
      }
      @media (max-width: 1126px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media (max-width: 767px) {
        /* gap: 34px 15px;
        padding: 30px 17px 0; */

        grid-template-columns: 1fr 1fr 1fr;
        gap: 15px;
        padding: 9px 17px;
        & .text {
          margin-top: 3px;
        }
      }

      & > div {
        /* width: calc(16% - 49px); */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 1630px) {
          /* width: calc(20% - 60px); */
        }
        @media (max-width: 1126px) {
          /* width: calc(25% - 60px); */
        }
        @media (max-width: 767px) {
          /* width: calc(33% - 20px); */
          margin-bottom: 30px;
        }
      }
    }
  }
  & .media {
    background: var(--matterColorBright);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 95px;
    @media (min-width: 767px) {
      height: 195px;
    }
    @media (min-width: 1680px) {
      height: 195px;
      width: 191.429px;
    }
    & a {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: 1023px) {
      & svg {
        max-height: 55px;
      }
    }

    &:hover {
      background: var(--marketplaceInitialDefaultColor);
      & svg {
        & path {
          fill: #fff !important;
        }
      }
    }
    & > div {
      & > div {
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    & img {
      object-fit: contain !important;
      max-width: 92px;
    }
  }
  & .text {
    text-align: center;
    & h3 {
      @media (max-width: 767px) {
        font-size: 14px;
        font-style: normal;
      }
    }
  }
}

:global(#articles-section) {
  & > div {
    padding-top: 0;
    & header {
      border-top: 1px solid #8e8e8e;
      text-align: left;
      display: block;
      max-width: 1560px;
      width: 100%;
      margin: 0 auto;
      padding: 32px 0 0;
      @media (max-width: 1630px) {
        padding: 32px 22px 0 22px;
      }
      & h2 {
        text-align: left;
        color: var(--matterColor);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 56px; /* 233.333% */
        text-transform: uppercase;
        max-width: 100%;
        @media (max-width: 767px) {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
    & > div {
      padding-top: 22px;
      @media (max-width: 767px) {
        padding: 20px 17px;
      }
    }
  }
  & .text {
    & h3 {
      color: var(--matterColor);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 33px; /* 206.25% */
    }
    & p {
      color: var(--matterColor);
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 33px; /* 150% */
    }
  }
}

:global(#bags-category) {
  & .media {
    &:hover {
      background: var(--marketplaceInitialDefaultColor);
      & svg {
        & path {
          fill: transparent !important;
          stroke: #fff !important;
        }
      }
    }
  }
}
